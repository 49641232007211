/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-floating-promises */

import userInfo from 'hub-http/userInfo';
import { onResponseError } from 'hub-http/middlewares/core';
const COOKIE_NOT_AUTH = 'Cookie is not authorized';

/**
 * @description A hub-http middleware that busts the user
 * info cache.
 */
export const checkExpiredCookie = onResponseError(error => {
  if (error instanceof Error &&
  // Casting of any is required due to the incorrect typing of the
  // error object in hub-http. This is a known issue.
  error.status === 403 && error.responseJSON && error.responseJSON.message === COOKIE_NOT_AUTH) {
    userInfo({
      cached: false
    });
  }
  return Promise.reject(error);
});