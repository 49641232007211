'use es6';

import http from 'conversations-http/clients/http';
import applyDefaults from './applyDefaults';
const apiClient = {
  post: (url, data) => applyDefaults(http.post, url, data),
  put: (url, data) => applyDefaults(http.put, url, data),
  get: (url, data) => applyDefaults(http.get, url, data),
  delete: (url, data) => applyDefaults(http.delete, url, data)
};
export default apiClient;