'use es6';

import { createAction } from '@reduxjs/toolkit';
import { ADD_TYPING_TIMEOUT_ID } from '../constants/typingIndicatorActionTypes';
export const addTypingTimeoutIdForAgentInThread = createAction(ADD_TYPING_TIMEOUT_ID, (threadId, senderId, timeoutId) => ({
  payload: {
    threadId,
    senderId,
    timeoutId
  }
}));