import { BATTLESHIP, HEFFALUMP, KOALA, OLAF, OBSIDIAN } from 'HubStyleTokens/colors';
import FileAttachmentTheme from '../../attachments/model/FileAttachmentTheme';
import QuickReplyTheme from '../../quick-reply/model/QuickReplyTheme';

/**
 * Represents the default values consumed by `PrimaryMessage` components required for rendering.
 */

const defaultTheme = {
  alignItems: 'flex-start',
  avatarWidth: '32px',
  backgroundColor: KOALA,
  borderColor: BATTLESHIP,
  borderRadius: '4px',
  borderWidth: '1px',
  messageSpacing: '8px',
  textColor: HEFFALUMP,
  fileAttachmentTheme: FileAttachmentTheme(),
  quickReplyTheme: QuickReplyTheme(),
  widgetAccent: HEFFALUMP,
  headerBackgroundColor: OLAF,
  headerTextColor: OBSIDIAN,
  avatarBackgroundColor: BATTLESHIP,
  avatarBorderColor: BATTLESHIP,
  avatarTextColor: OBSIDIAN
};
const Theme = themeProps => Object.assign({}, defaultTheme, themeProps);
export default Theme;