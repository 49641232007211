'use es6';

import PortalIdParser from 'PortalIdParser';
import noAuthHttp from '../../http/noAuthApiClient';
import authHttp from '../../http/apiClient';
export function createVisitorThread({
  sessionId,
  messageId,
  hubspotUtk,
  messagesPageUri,
  visitorThreadInitialHistory,
  zoneId
}, shouldUseNewEndpoint = false) {
  const isInPreview = new URLSearchParams(window.location.search).get('preview') === 'true';
  let url = shouldUseNewEndpoint ? `livechat-public/v1/thread/visitor/create` : `livechat-public/v1/thread/visitor`;
  if (shouldUseNewEndpoint && isInPreview) {
    url = `livechat/v1/thread/visitor/preview/create`;
  }
  const data = shouldUseNewEndpoint ? {
    visitorMessage: visitorThreadInitialHistory[visitorThreadInitialHistory.length - 1],
    expectedInitialHistory: visitorThreadInitialHistory.slice(0, -1).map(message => message.richText || message.text)
  } : {
    visitorThreadInitialHistory
  };
  const client = isInPreview ? authHttp : noAuthHttp;
  return client.post(url, {
    query: {
      sessionId,
      welcomeMessageId: messageId,
      hubspotUtk,
      portalId: PortalIdParser.get(),
      expectedResponseType: 'WRAPPER_V2',
      zoneId
    },
    data,
    headers: {
      'X-HubSpot-Messages-Uri': messagesPageUri
    }
  });
}