import { CONSENT_TO_PROCESS_CLOSED } from '../constants/ActionTypes';
import { createAction } from '@reduxjs/toolkit';
import { trackInteraction } from '../../usage-tracking/actions/trackInteraction';
export const closedConsentToProcess = createAction(CONSENT_TO_PROCESS_CLOSED);
export function closeConsentToProcess() {
  return dispatch => {
    dispatch(trackInteraction('widget-interaction', {
      action: 'close consent to process'
    }));
    dispatch(closedConsentToProcess());
  };
}