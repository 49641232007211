import filter from 'transmute/filter';
import { createSelector } from '@reduxjs/toolkit';

// @ts-expect-error Untyped import
import { getThreadList } from './getThreadList';
// @ts-expect-error Untyped import
import { isStarted } from '../operators/isStarted';
import { getHasChannelSwitchedToEmail } from '../operators/threadGetters';
export const getMostRecentNonTransferredOpenThread = createSelector([getThreadList], threads => {
  return filter(thread => isStarted(thread) && !getHasChannelSwitchedToEmail(thread), threads).first();
});