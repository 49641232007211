'use es6';

import { trackInteraction } from '../../usage-tracking/actions/trackInteraction';
import { updateShowExitIntentCookieBanner } from '../../visitor-identity/actions/updateShowExitIntentCookieBanner';
import { gdprCookieConsentOnExitIntentEnabled } from '../../utils/gdprCookieConsentPromptHelpers';
import { getAPIEnableWidgetCookieBannerOnExitIntent } from '../../widget-ui/selectors/getAPIEnableWidgetCookieBannerOnExitIntent';
export function declineCookies() {
  return (dispatch, getState) => {
    if (gdprCookieConsentOnExitIntentEnabled(getState()) || getAPIEnableWidgetCookieBannerOnExitIntent(getState())) {
      dispatch(updateShowExitIntentCookieBanner(false));
    }
    dispatch(trackInteraction('widget-interaction', {
      action: 'clicked decline cookies button'
    }));
  };
}