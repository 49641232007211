export const LIVE_CHAT_GENERIC_CHANNEL_ID = 1000;
export const FB_MESSENGER_GENERIC_CHANNEL_ID = 1001;
export const EMAIL_GENERIC_CHANNEL_ID = 1002;
export const FORMS_GENERIC_CHANNEL_ID = 1003;
export const CUSTOMER_PORTAL_GENERIC_CHANNEL_ID = 1004;
export const COMMENTS_GENERIC_CHANNEL_ID = 1005;
export const FEEDBACK_GENERIC_CHANNEL_ID = 1006;
export const WHATSAPP_GENERIC_CHANNEL_ID = 1007;
export const CALLING_GENERIC_CHANNEL_ID = 1008;
export const SMS_GENERIC_CHANNEL_ID = 1009;

/**
 * The channel ids specifically supported by HubSpot, as opposed to a channel id created as an integration to the channel framework by 3rd parties.
 *
 * These IDs should correspond to https://git.hubteam.com/HubSpot/cv-common/blob/master/cv-common-core/src/main/java/com/hubspot/cv/common/core/HubSpotGenericChannel.java
 */