// @ts-expect-error untyped file
import noAuthHttp from '../http/noAuthApiClient';
export const fetchFeedbackSurveyEnabled = ({
  id,
  sessionId
}) => {
  return noAuthHttp.get(`livechat-public/v1/feedback/survey/${id}`, {
    query: {
      sessionId
    }
  });
};