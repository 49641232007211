import { createContainer } from 'usage-tracker-multi';
import PortalIdParser from 'PortalIdParser';
import events from 'conversations-visitor-ui/events.yaml'; // eslint-disable-line
import { PAGEVIEW_SAMPLE_PERCENT } from '../constants/pageViewSamplePercent';
const {
  createTracker
} = createContainer({
  storeUuidOnUrl: false
});
export function makeUsageTracker({
  messagesUtk
}) {
  return createTracker({
    events,
    trackerType: messagesUtk ? 'public' : 'anonymous',
    properties: {
      hubId: PortalIdParser.get(),
      // HACK!!! internally, usage-tracker splits the hstc on "." and takes the second item
      hstc: messagesUtk ? `.${messagesUtk}` : null,
      isIncludedInPageViewSample: Math.random() < PAGEVIEW_SAMPLE_PERCENT
    }
  });
}