/* hs-eslint ignored failing-rules */

'use es6';

import { createAction } from '@reduxjs/toolkit';
import { CONSENT_TO_PROCESS, CONSENT_TO_PROCESS_SUCCEEDED, CONSENT_TO_PROCESS_FAILED } from '../constants/ActionTypes';
import { getSessionId } from '../../selectors/widgetDataSelectors/getSessionId';
import { getMessageId } from '../../selectors/widgetDataSelectors/getMessageId';
import { sendConsentToProcess } from '../client/ConsentToProcessClient';
import { handleStoreMessagesCookie } from '../../post-message/handleStoreMessagesCookie';
import { gdprCookieConsentOnExitIntentEnabled } from '../../utils/gdprCookieConsentPromptHelpers';
import { updateSessionId } from '../../widget-data/actions/updateSessionId';
import { getHubspotUtk } from '../../query-params/hubspotUtk';
import { trackInteraction } from '../../usage-tracking/actions/trackInteraction';
import { getLatestWidgetData } from '../../widget-data/selectors/getLatestWidgetData';
import { getMessagesUtk } from '../../query-params/getMessagesUtk';
export const clickedConsentToProcess = createAction(CONSENT_TO_PROCESS);
export const consentToProcessSucceeded = createAction(CONSENT_TO_PROCESS_SUCCEEDED);
export const consentToProcessFailed = createAction(CONSENT_TO_PROCESS_FAILED, ({
  error
}) => ({
  payload: {
    error
  }
}));
export function consentToProcess() {
  return (dispatch, getState) => {
    const sessionId = getSessionId(getState());
    const welcomeMessageId = getMessageId(getState());
    const hubspotUtk = getHubspotUtk();
    const widgetData = getLatestWidgetData(getState());
    dispatch(clickedConsentToProcess());
    sendConsentToProcess({
      sessionId,
      hubspotUtk,
      welcomeMessageId,
      widgetData
    }).then(res => {
      const {
        sessionId: newSessionId
      } = res;
      const cookieConsentOnExitEnabled = gdprCookieConsentOnExitIntentEnabled(getState(), widgetData);
      try {
        localStorage.setItem('userHasGivenConsentToProcess', true);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn('local storage not set!');
      }
      if (!cookieConsentOnExitEnabled) {
        handleStoreMessagesCookie(getMessagesUtk());
      }
      dispatch(updateSessionId(newSessionId));
      dispatch(consentToProcessSucceeded());
      dispatch(trackInteraction('widget-interaction', {
        action: 'clicked consent to process button'
      }));
    }, error => dispatch(consentToProcessFailed({
      error
    }))).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
  };
}