import { FLINT } from 'HubStyleTokens/colors';
const defaultQuickReplyTheme = {
  width: 'auto',
  minHeight: 36,
  borderColor: FLINT,
  whitespace: 'normal',
  lineHeight: '1rem',
  color: FLINT
};
const QuickReplyTheme = themeProps => Object.assign({}, defaultQuickReplyTheme, themeProps);
export default QuickReplyTheme;