'use es6';

import { connect } from 'react-redux';
import { getChatHeadingConfig } from '../../chat-heading-config/selectors/getChatHeadingConfig';
import { getChatHeadingResponders } from '../../responders/selectors/getChatHeadingResponders';
import { getIsMobile } from '../../selectors/getIsMobile';
import { getInitialMessageText } from '../../selectors/widgetDataSelectors/getInitialMessageText';
import { closeInitialMessageBubble } from '../actions/closeInitialMessageBubble';
import { threadHistoryToMessageList } from 'conversations-message-history/thread-history/operators/threadHistoryToMessageList';
import InitialMessageBubble from 'conversations-visitor-experience-components/visitor-widget/InitialMessageBubble';
import { showQuickReplyOutsideChat } from '../../utils/showQuickRepliesOutsideChat';
import { publishMessageFromAIWelcomeMessageTextInput } from '../../ai-bot/actions/publishMessageFromAIWelcomeMessageTextInput';
import { getIsAIChatbot } from '../../selectors/widgetDataSelectors/getIsAIChatbot';
import { getIsUngatedForUpdatedUI } from '../../widget-data/selectors/widgetDataSelectors';
import { getIsIOSMobile } from '../../selectors/getIsIOSMobile';
import { getGdprConsentToProcessEnabledForChatflow } from '../../gdpr/selectors/getGdprConsentToProcessEnabledForChatflow';
import { shouldRequireExplicitCookieConsent } from '../../selectors/widgetDataSelectors/shouldRequireExplicitCookieConsent';
import { getUserHasGivenConsentToProcess } from '../../gdpr/selectors/getHasUserGivenConsentToProcess';
const mapStateToProps = state => {
  const messageRecords = threadHistoryToMessageList(state.stagedThread.data);
  const displayBotQuickRepliesOutsideChat = showQuickReplyOutsideChat(state);
  const initialMessage = displayBotQuickRepliesOutsideChat ? messageRecords.reduce((acc, msg) => acc += ` ${msg.text}`, '') : getInitialMessageText(state);
  const showCookieConsent = shouldRequireExplicitCookieConsent(state);
  const showProcessConsent = getGdprConsentToProcessEnabledForChatflow(state) && !getUserHasGivenConsentToProcess(state);
  return {
    chatHeadingConfig: getChatHeadingConfig(state),
    chatHeadingResponders: getChatHeadingResponders(state),
    initialMessage,
    mobile: getIsMobile(state),
    isIOSMobile: getIsIOSMobile(state),
    isAIChatBot: getIsAIChatbot(state),
    gdprConsentEnabled: showCookieConsent || showProcessConsent,
    isUngatedForUpdatedUI: getIsUngatedForUpdatedUI(state)
  };
};
const mapDispatchToProps = {
  onClose: closeInitialMessageBubble,
  publishAIInitialMessage: publishMessageFromAIWelcomeMessageTextInput
};
export default connect(mapStateToProps, mapDispatchToProps)(InitialMessageBubble);