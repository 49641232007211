export const AGENT_ACTOR = 'A';
export const BOT_ACTOR = 'B';
export const INTEGRATOR_ACTOR = 'I';
export const SYSTEM_ACTOR = 'S';
export const VISITOR_ACTOR = 'V';
export const EXTERNAL_ACTOR = 'X';
export const LLM_ACTOR = 'L';
export const ACTOR_TYPES = [AGENT_ACTOR, BOT_ACTOR, INTEGRATOR_ACTOR, SYSTEM_ACTOR, VISITOR_ACTOR, EXTERNAL_ACTOR, LLM_ACTOR];

// Deprecated
export const EMAIL_ACTOR = 'E';