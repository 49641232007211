import { getWidgetShellUUID } from '../query-params/getWidgetShellUUID';
export const postMessageToParent = (type, data) => {
  try {
    const uuid = getWidgetShellUUID();
    window.parent.postMessage(JSON.stringify({
      type,
      data,
      uuid
    }), '*');
    return {
      type,
      data
    };
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error posting message to parent', e);
  }
};