import { HELP_DESK_TICKET_SPLIT_UPDATE_MESSAGE } from '../constants/messageTypes';
import { List, Record } from 'immutable';
const HelpdeskTicketSplitUpdateMessage = Record({
  '@type': HELP_DESK_TICKET_SPLIT_UPDATE_MESSAGE,
  id: null,
  timestamp: null,
  echo: false,
  messageDeletedStatus: 'NOT_DELETED',
  ablyTs: null,
  originatingTicketId: null,
  splitTicketId: null,
  splitTicketCreationTimestamp: null,
  clientType: null,
  senders: List(),
  attachments: List()
}, 'HelpdeskTicketSplitUpdateMessage');
export default HelpdeskTicketSplitUpdateMessage;